<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-button type="success" size="small" icon="el-icon-plus" @click="open('add')">新增{{typeName}}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <!-- tree-table表格组件 -->
      <tree-table class="treeTable" :is-fold="false" :data="orgList" :expand-type="false" :columns="columns" :selection-type="false" :show-row-hover="true" stripe>
        <template slot="opt" slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="修改" placement="top">
            <el-button type="warning" icon="el-icon-edit" circle size="mini" @click="open('edit', scope.row)"></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="删除" placement="top">
            <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteOrg(scope.row)"></el-button>
          </el-tooltip>
        </template>
      </tree-table>
    </div>
    <!-- 组织机构弹窗 -->
    <el-dialog :title="optFlag?`添加${typeName}`:`修改${typeName}`" :visible.sync="dialog1" width="30%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="orgForm" ref="orgForm" :rules="rules">
        <el-form-item label="机构名称" prop="name" :label-width="formLabelWidth">
          <el-input v-model="orgForm.name" autocomplete="off" placeholder="请输入机构名称"></el-input>
        </el-form-item>
        <el-form-item label="行政区域" prop="areaCodes" :label-width="formLabelWidth">
          <el-cascader :disabled="isLoad" v-model="orgForm.areaCodes" placeholder="请选择该机构的行政区域" :options="areaList" :props="{ checkStrictly: true }" clearable style="width:100%"></el-cascader>
        </el-form-item>
        <el-form-item label="上级机构" prop="parentIds" :label-width="formLabelWidth">
          <el-cascader v-model="orgForm.parentIds" placeholder="请选择父级菜单" :options="orgList" :props="{ checkStrictly: true }" clearable style="width:100%"></el-cascader>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="dialog1 = false">取 消</el-button>
        <el-button type="primary" @click="addOrg" v-if="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateOrg" v-if="!optFlag">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TreeTable from 'vue-table-with-tree-grid'
export default {
  components: {
    TreeTable
  },
  data () {
    return {
      isLoad: false,
      areaList: [],
      typeName: '',
      parentId: null,
      typeId: 0,
      formLabelWidth: '100px',
      dialog1: false, // 行政区域弹窗开关
      orgList: [], // 行政区域列表
      optFlag: true,
      orgForm: {
        name: '',
        parentIds: [],
        areaCodes: []
      },
      orgId: 0,
      orgFormCopy: {},
      columns: [
        {
          label: '机构名称',
          prop: 'label'
        },
        {
          label: '行政编号',
          prop: 'areaCode'
        },
        {
          label: '操作',
          type: 'template',
          template: 'opt'
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入组织机构名称', trigger: 'blur' },
          { pattern: /(^$)|(^[\u4E00-\u9FA5a-zA-Z0-9]{1,10}$)/, message: '请输入合法的组织机构名称', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    '$route.path': 'changeRoute'
  },
  mounted () {
    this.orgFormCopy = JSON.stringify(this.orgForm)
    this.changeRoute()
    this.getAreaList()
  },
  created () {
    // 判断组织类型
    switch (parseInt(this.$route.params.id)) {
      case 1:
        this.typeName = '消防部门'
        this.parentId = parseInt(this.$route.params.id)
        this.typeId = 12
        break
      case 2:
        this.typeName = '运维公司'
        this.parentId = parseInt(this.$route.params.id)
        this.typeId = 13
        break
      case 3:
        this.typeName = '安装公司'
        this.parentId = parseInt(this.$route.params.id)
        this.typeId = 14
        break
    }
    this.initOrg()
  },
  methods: {
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.dialog1 = true
          this.optFlag = true
          break
        case 'edit':
          this.dialog1 = true
          this.$nextTick(() => {
            this.$refs.orgForm.clearValidate()
          })
          this.optFlag = false
          this.orgForm = JSON.parse(JSON.stringify(row))
          this.$set(this.orgForm, 'name', row.label)
          this.orgId = row.value
          break
      }
    },
    // 查找组织机构
    async initOrg () {
      const { data: result } = await this.$axios.get('/organization/listVOWeb', { params: { parentId: this.parentId } })
      if (result.code === 200) {
        this.orgList = result.data
        this.total = result.total
      } else {
        this.$message.error(result.msg)
      }
    },
    // 删除组织机构
    async deleteOrg (row) {
      const confirm = await this.$confirm('此操作将删除该行政区域, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/organization/del/${row.value}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          this.initOrg()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    // 添加组织机构
    async addOrg () {
      this.$refs.orgForm.validate(async valid => {
        if (valid) {
          if (this.orgForm.parentIds.length > 0) {
            this.orgForm.parentId = this.orgForm.parentIds[this.orgForm.parentIds.length - 1]
          } else {
            this.orgForm.parentId = this.parentId
          }
          if (this.orgForm.areaCodes.length > 0) {
            this.orgForm.areaCode = this.orgForm.areaCodes[this.orgForm.areaCodes.length - 1]
          } else {
            this.$message.error('请选择该机构所在行政区域')
            return
          }
          this.orgForm.organizedType = this.typeId
          const { data: result } = await this.$axios.post('/organization/add', this.orgForm)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.dialog1 = false
            this.initOrg()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 修改组织机构
    async updateOrg () {
      this.$refs.orgForm.validate(async valid => {
        if (valid) {
          if (this.orgForm.parentIds.length > 0) {
            this.orgForm.parentId = this.orgForm.parentIds[this.orgForm.parentIds.length - 1]
          } else {
            this.orgForm.parentId = this.parentId
          }
          if (this.orgForm.areaCodes.length > 0) {
            this.orgForm.areaCode = this.orgForm.areaCodes[this.orgForm.areaCodes.length - 1]
          } else {
            this.$message.error('请选择该机构所在行政区域')
            return
          }
          const { data: result } = await this.$axios.put(`/organization/edit/${this.orgId}`, this.orgForm)
          if (result.code === 200) {
            this.$message.success('修改成功')
            this.dialog1 = false
            this.initOrg()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 查找行政区域
    async getAreaList () {
      const { data: result } = await this.$axios.get('/area/listByUser')
      if (result.code === 200) {
        if (result.data.length === 0) {
          this.isLoad = true
          return
        }
        this.areaList = this.changeKey(result.data)
      } else {
        this.$message.error(result.msg)
      }
    },
    clear () {
      this.orgForm = JSON.parse(this.orgFormCopy)
      this.$refs.orgForm.resetFields()
    },
    changeKey (array) {
      array.forEach((item) => {
        [item.value, item.areaCode] = [item.areaCode, item.value]
        if (item.children) {
          item.children.forEach((subItem) => {
            [subItem.value, subItem.areaCode] = [subItem.areaCode, subItem.value]
            if (subItem.children) {
              subItem.children.forEach((subItem2) => {
                [subItem2.value, subItem2.areaCode] = [subItem2.areaCode, subItem2.value]
              })
            }
          })
        }
      })
      return array
    },
    changeRoute () {
      switch (parseInt(this.$route.params.id)) {
        case 1:
          this.typeName = '消防部门'
          this.parentId = parseInt(this.$route.params.id)
          this.typeId = 12
          break
        case 2:
          this.typeName = '运维公司'
          this.parentId = parseInt(this.$route.params.id)
          this.typeId = 13
          break
        case 3:
          this.typeName = '安装公司'
          this.parentId = parseInt(this.$route.params.id)
          this.typeId = 14
          break
      }
      this.initOrg()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 10px;
  .content {
    overflow-y: auto !important;
  }
}
::v-deep .el-table__expanded-cell {
  padding: 0;
}
</style>
